// Importing styles
import "./header.scss";

// Importing assets
import logo from "../../assets/images/logo.jpeg";

// Importing modules
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Container, Row, Col, Nav, Navbar, Button } from "react-bootstrap";
import { ArrowDown, ArrowUp, EyeFill } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { SharedText } from "../../data/main.ts";

const Header = (props) => {
  const sections = props?.sections;
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const [largeFont, setLargeFont] = useState();
  const [theme, setTheme] = useState();

  const checkCookieExistence = (name) => {
    const cookies = document.cookie;
    const cookieArray = cookies.split(";");

    const isCookieExists = cookieArray.some((cookie) => {
      const trimmedCookie = cookie.trim();
      return trimmedCookie.startsWith(name);
    });

    return isCookieExists;
  };

  useEffect(() => {
    if (checkCookieExistence("largeFont")) {
      updateBodyClass("add", "large", "fontSize");
    }
    if (checkCookieExistence("theme")) {
      updateBodyClass("add", "contrast", "theme");
    }
  }, []);

  const setCookie = (
    name,
    value,
    expires = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
    path = "/"
  ) => {
    document.cookie = `${name}=${value}; expires=${expires}; path=${path}`;
  };

  const removeCookie = (name, path = "/") => {
    const expirationDate = new Date("Thu, 01 Jan 1970 00:00:00 GMT");
    document.cookie = `${name}=; expires=${expirationDate.toUTCString()}; path=${path}`;
  };

  const updateBodyClass = (action, className, cookieName) => {
    if (action === "add") {
      if (cookieName === "fontSize") {
        setLargeFont(className === "large" ? "large" : null);
      }
      if (cookieName === "theme") {
        setTheme(className === "contrast" ? "contrast" : null);
      }
    } else {
      if (cookieName === "fontSize") {
        setLargeFont(null);
      }
      if (cookieName === "theme") {
        setTheme(null);
      }
    }
    document.documentElement.classList[action](className);
  };

  const addLargeClassToBody = () => {
    updateBodyClass("add", "large", "fontSize");
    setCookie("largeFont", "large");
  };

  const removeLargeClassFromBody = () => {
    updateBodyClass("remove", "large", "fontSize");
    removeCookie("largeFont");
  };

  const addContrastClassToBody = () => {
    updateBodyClass("add", "contrast", "theme");
    setCookie("theme", "contrast");
  };

  const removeContrastClassFromBody = () => {
    updateBodyClass("remove", "contrast", "theme");
    removeCookie("theme");
  };

  return (
    <header className={`header`}>
      <Navbar expand="lg" bg="light" expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <img
              src="/static/media/logo.8a9b1e7bd878e7e770e5.jpeg"
              class="header__logo"
              alt="Logo - przekierowanie na stronę główną"
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link eventKey="link-1" key={0} aria-hidden="false">
                  Strona Główna
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to="/About">
                <Nav.Link eventKey="link-2" key={1} aria-hidden="false">
                  O Usługach
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className="ms-auto">
              <div className="accessibility">
                {largeFont !== "large" && (
                  <Button
                    className="accessibility__fontsize"
                    aria-label="Zwiększ czcionkę"
                    size="sm"
                    onClick={addLargeClassToBody}
                  >
                    A
                    <ArrowUp />
                  </Button>
                )}
                {largeFont === "large" && (
                  <Button
                    className="accessibility__fontsize"
                    aria-label="Zmniejsz czcionkę"
                    size="sm"
                    onClick={removeLargeClassFromBody}
                  >
                    A
                    <ArrowDown />
                  </Button>
                )}
                {theme !== "contrast" && (
                  <Button
                    className="accessibility__contrast"
                    aria-label="Zwiększ Kontrast"
                    size="sm"
                    onClick={addContrastClassToBody}
                  >
                    <EyeFill />
                  </Button>
                )}
                {theme === "contrast" && (
                  <Button
                    className="accessibility__contrast"
                    aria-label=" Zmniejsz Kontrast"
                    size="sm"
                    onClick={removeContrastClassFromBody}
                  >
                    <EyeFill />
                  </Button>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  sections: PropTypes.array,
  location: PropTypes.string,
};

export default Header;
