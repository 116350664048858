

const apiUrl = process.env.REACT_APP_API_URL;

export async function getResultsfromApi(searchParams, page, pageSize) {
    try {
      const params = {
        ...searchParams,
        skip: (page - 1) * pageSize,
        take: page * pageSize,
      };

      const par = {
        query: {...searchParams},
        pageSize: pageSize,
        page: page - 1
      }

      var response = await fetch(`${apiUrl}/services/search`, {
        method: 'POST',
        // mode: 'no-cors', // this makes the request opaque
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(par)
      });
  
      return response.json();
  
    } catch (error) {
      console.log("error " + error);
      return { type: 'error', body: error };
    }
  }

const postData = async (url = '', data = {}) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json(); // parses JSON response into native JavaScript objects
  };