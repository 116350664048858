// Importing styles
import './loader.scss';

import React from 'react';

const ContentSpinner = ({loading}) => {
  
  return (
    <div className={`content-spinner__wrapper ${loading ? 'visible' : 'hidden'}`}>
      <div
        className="content-spinner"
      ></div>
    </div>
  );
};

export default ContentSpinner;
