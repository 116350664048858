// Importing styles
import "./banner.scss";

// Importing assets
import logo from "../../assets/images/logo.jpeg";

// Importing modules
import { Container, Row, Col } from "react-bootstrap";
import { SharedText } from '../../data/main.ts';

const Banner = () => {
  return (
    <section className="banner" role="banner">
      <Container fluid="lg">
        <Row>
          <Col>
            <div className="banner__content">
              <div className="banner__logo-wrapper">
              </div>
              <div className="banner__headline">
                <h1 dangerouslySetInnerHTML={{ __html: SharedText.HomeTitle}}/>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
