// Importing styles
import "./Error404.scss";

// Importing modules
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { SharedText } from "../../data/main.ts";

const Error404 = () => {
  return (
    <div className="error page">
      <section role="banner" className="search__banner">
        <Container fluid="lg">
          <Row>
            <h1>{SharedText.Error404}</h1>
          </Row>
        </Container>
      </section>

      <section>
        <Container fluid="lg">
          <div className="about-wrapper">
            <article className="article">
              <header className="about__header">
                <h2 className="about-title">{SharedText.Error404}</h2>
              </header>
              <section className="about__section">
                <p>
                  Przykro nam, ale strona, której szukasz, nie istnieje. Może
                  została usunięta lub nigdy nie istniała.
                </p>
                <p>
                  Nie martw się! Możesz wrócić na naszą stronę główną lub
                  skorzystać z poniższych linków, aby znaleźć to, czego
                  potrzebujesz:
                </p>
                <p>
                  <Link to="/">Strona Główna</Link>&nbsp; |
                  &nbsp; <Link to="/About">O Usługach</Link>
                </p>
                <p>Dziękujemy za odwiedzenie naszej strony!</p>
              </section>
              <footer className="about__footer"></footer>
            </article>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Error404;
