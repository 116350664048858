// Importing styles
import "./searchWidget.scss";
import "./multiSelect.scss";
import "../../assets/styles/_form.scss";

// Importing modules
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Search } from "react-bootstrap-icons";
import Select from "react-select";
import MultiSelectDropdown from "./MultiSelectDropdown";
import { FormText, ButtonText } from "../../data/main.ts";

// Importing queries and client
import { getDatafromSanity } from "../../services/SearchService";
import { FormGroup } from "react-bootstrap";

const generateSearchParamsUrl = (searchQuery) => {
  const urlQuery = Object.fromEntries(
    Object.entries(searchQuery).filter(
      ([_, value]) => value && (!Array.isArray(value) || value.length)
    )
  );

  const result = new URLSearchParams(urlQuery);
  return result.toString();
};

const readSearchParamsUrl = (searchParams) => {
  const query = {};

  const phrases = searchParams.get("phrases");
  if (phrases) {
    query.phrases = phrases;
  }

  const selectedDistrict = searchParams.get("district");
  if (selectedDistrict) {
    query.district = selectedDistrict;
  }
  const selectedCommunies = searchParams.get("communies")?.split(",");
  if (selectedCommunies) {
    query.communies = selectedCommunies;
  }
  const selectedRanges = searchParams.get("ranges")?.split(",");
  if (selectedRanges) {
    query.ranges = selectedRanges;
  }
  const selectedTargets = searchParams.get("targets")?.split(",");
  if (selectedTargets) {
    query.targets = selectedTargets;
  }
  const isPaid = searchParams.get("paid");
  if (isPaid) {
    query.paid = true;
  }
  const isFree = searchParams.get('free');
  if (isFree) {
    query.free = true;
  }
  const isRemote = searchParams.get("remote");
  if (isRemote) {
    query.remote = true;
  }
  const isLocal = searchParams.get('local');
  if (isLocal) {
    query.local = true;
  }
  const commuting = searchParams.get("commuting");
  if (commuting) {
    query.commuting = true;
  }

  return query;
};

const validate = (searchQuery) => {
  const isValid =
    Object.entries(searchQuery).filter(
      ([_, value]) => value && (!Array.isArray(value) || value.length)
    ).length > 0;
  return isValid;
};

const SearchWidget = ({
  layout,
  onParamsChange,
  setCurrentPage,
  isPaginationVisible,
  setIsApiError
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState({
    phrases: '',
    district: '',
    communies: [],
    ranges: [],
    targets: [],
    local: false,
    remote: false,
    commuting: false,
    free: false,
    paid: false,
  });

  const [filters, setFilters] = useState(null);
  const [communiesFilter, setCommuniesFilter] = useState([]);
  const [isValid, setValid] = useState(false);
  const [isError, setIsError] = useState(false);

    const paidOptions = [
    { label: "Darmowa", value: "isFree" },
    { label: "Płatna", value: "isPaid" },
    ];

    const localizationOptions = [
      { label: 'Z dojazdem', value: 'isCommuting' },
      { label: 'Lokalna', value: 'isLocal' },
      { label: 'Zdalna', value: 'isRemote' },
    ];


  useEffect(() => {
    getDatafromSanity().then((data) => {
      if (data && !('type' in data)) {
        setFilters(data);
        const query = { ...searchQuery, ...readSearchParamsUrl(searchParams) };
        setSearchQuery(query);
        sendQuery(query);
        setIsError(false);
        setIsApiError(false);

        if (query.district) {
          var district = data.districts.find((d) => d.value === query.district);
          district && setCommuniesFilter(district.communies);
        }
      } else {
        setIsError(true);
        setIsApiError(true);
      }
    });
  }, []);

  useEffect(() => {
    var isValid = validate(searchQuery);
    setValid(isValid);
  }, [searchQuery]);



  const sendQuery = (query) => {
    onParamsChange && onParamsChange(query);
  };

  const handleSearchValueChange = (event) => {
    const phrases = event.target.value;
    setSearchQuery({ ...searchQuery, phrases: phrases });
  };

  const handleCountyValueChange = (event) => {
    if (!event) {
      setSearchQuery({ ...searchQuery, district: '', communies: [] });
      setCommuniesFilter([]);
      return;
    }
    setCommuniesFilter(event.communies);
    setSearchQuery({ ...searchQuery, district: event.value, communies: [] });
  };

  const handleMultiSelectValueChange = (value, name) => {
    switch (name) {
      case 'community':
        setSearchQuery({ ...searchQuery, communies: value });
        break;
      case 'services':
        setSearchQuery({ ...searchQuery, ranges: value });
        break;
      case 'group':
        setSearchQuery({ ...searchQuery, targets: value });
        break;
      case 'payment':
        setSearchQuery({
          ...searchQuery,
          paid: value.includes('isPaid'),
          free: value.includes('isFree'),
        });
        break;
      case 'localization':
        setSearchQuery({
          ...searchQuery,
          commuting: value.includes('isCommuting'),
          local: value.includes('isLocal'),
          remote: value.includes('isRemote'),
        });
        break;
      default:
    }
  };

  const createSelectableObject = (name) => {
    const selectableObject = [];
     switch (name) {
      case 'payment':
        if(searchQuery.paid) selectableObject.push('isPaid');
        if(searchQuery.free) selectableObject.push('isFree');
        break;
      case 'localization':
        if(searchQuery.local) selectableObject.push('isLocal');
        if(searchQuery.remote) selectableObject.push('isRemote');
        if(searchQuery.commuting) selectableObject.push('isCommuting');
        break;
        default:
     }
    return selectableObject;
  }

  const sendForm = (e) => {
    //debugger;
    e.preventDefault();

    const query = generateSearchParamsUrl(searchQuery);
    
    sendQuery(searchQuery);

    if (isPaginationVisible) {
      setCurrentPage(1);
    }
    navigate(`/Search?${query}`);
  };

  return (
    !isError &&
    filters && (
      <div className={`search-widget search-widget--${layout}`}>
        <Container fluid="lg">
          <Row>
            <Col>
              <Form
                onSubmit={sendForm}
                className=" form search-widget__form"
                role="search"
              >
                <Row>
                  <Form.Group
                    className="mb-2 search-widget--searchbar"
                    controlId="search-widget--searchbar"
                  >
                    <Form.Label>Fraza</Form.Label>
                    <Search className="search-widget--icon" />
                    <Form.Control
                      type="text"
                      value={searchQuery.phrases}
                      onChange={handleSearchValueChange}
                      placeholder={FormText.SearchPlaceholder}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    md={4}
                    xs={12}
                    className="mb-2 search-widget--select"
                    controlId="search-widget--county"
                  >
                    <Form.Label id="label-county">
                      {FormText.LabelCounty}
                    </Form.Label>
                    <Select
                      options={filters.districts}
                      labelledBy="label-county"
                      name="county"
                      onChange={handleCountyValueChange}
                      className="multiselect"
                      classNamePrefix="multiselect__inner"
                      placeholder={FormText.SelectPlaceholder}
                      isClearable={true}
                      isSearchable={false}
                      inputId="search-widget--county"
                      value={filters.districts.filter(
                        (option) => option.value === searchQuery.district
                      )}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    xs={12}
                    className="mb-2 search-widget--select"
                    controlId="search-widget--community"
                  >
                    <Form.Label
                      id="label-community"
                      className={
                        !communiesFilter || communiesFilter.length <= 0
                          ? 'disabled'
                          : ''
                      }
                    >
                      {FormText.LabelCommunity}
                    </Form.Label>
                    <MultiSelectDropdown
                      options={communiesFilter}
                      labelledBy="label-community"
                      name="community"
                      selected={searchQuery.communies}
                      isDisabled={
                        !communiesFilter || communiesFilter.length <= 0
                      }
                      handleMultiSelectValueChange={
                        handleMultiSelectValueChange
                      }
                    />
                  </Form.Group>
                  {/* <Form.Group
                    as={Col}
                    md={3}
                    xs={12}
                    className="mb-2 search-widget--select"
                    controlId="search-widget--services"
                  >
                    <Form.Label id="label-services">
                      {FormText.LabelRanges}
                    </Form.Label>
                    <MultiSelectDropdown
                      options={filters.ranges}
                      labelledBy="label-services"
                      name="services"
                      selected={searchQuery.ranges}
                      handleMultiSelectValueChange={
                        handleMultiSelectValueChange
                      }
                    />
                  </Form.Group> */}

                  <Form.Group
                    as={Col}
                    md={4}
                    xs={12}
                    className="mb-2 search-widget--select"
                    controlId="search-widget--group"
                  >
                    <Form.Label id="label-group">
                      {FormText.LabelGroup}
                    </Form.Label>
                    <MultiSelectDropdown
                      options={filters.targets}
                      labelledBy="label-group"
                      name="group"
                      selected={searchQuery.targets}
                      handleMultiSelectValueChange={
                        handleMultiSelectValueChange
                      }
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <FormGroup
                    as={Col}
                    md={4}
                    xs={12}
                    className="mb-2 search-widget--select"
                    controlId="search-widget--payment"
                  >
                    <Form.Label id="label-paid">
                      {FormText.LabelPayment}
                    </Form.Label>
                    <MultiSelectDropdown
                      options={paidOptions}
                      labelledBy="label-paid"
                      name="payment"
                      selected={createSelectableObject('payment')}
                      handleMultiSelectValueChange={
                        handleMultiSelectValueChange
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    as={Col}
                    md={4}
                    xs={12}
                    className="mb-2 search-widget--select"
                    controlId="search-widget--localization"
                  >
                    <Form.Label id="label-localization">
                      {FormText.LabelLocalization}
                    </Form.Label>
                    <MultiSelectDropdown
                      options={localizationOptions}
                      labelledBy="label-localization"
                      name="localization"
                      selected={createSelectableObject('localization')}
                      handleMultiSelectValueChange={
                        handleMultiSelectValueChange
                      }
                    />
                  </FormGroup>
                  
                  <Form.Group
                    as={Col}
                    md={4}
                    xs={12}
                    controlId="search-widget--submit"
                    className="d-grid gap-2"
                  >
                    <Button type="submit" size="lg" disabled={!isValid}>
                      {ButtonText.SubmitButton}
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
};
SearchWidget.propTypes = {
  layout: PropTypes.string,
};

export default SearchWidget;
