// Importing styles
import './details.scss';

// Importing modules
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AlertModal from '../../components/alerts/Alert';
import { AlertTypes, AlertText } from '../../data/alerts.ts';
import { SharedText } from '../../data/main.ts';
import { getSingleResultfromSanity } from '../../services/SearchService';
import { useParams } from 'react-router-dom';
import Result from '../../components/results/Result';
import ResultExtended from '../../components/results/ResultExtended';

const Details = () => {

  const [isApiError, setIsApiError] = useState(false);
  const [resultsData, setResultsData] = useState(null);
  
  const {id} = useParams();
  const itemId = id.split('id=').pop();

  useEffect(() => {
    getSingleResultfromSanity(itemId).then((data) => {
      if(data && !('type' in data)){
        setResultsData(data.result);
        setIsApiError(false);

      } else {
        console.log('There are errors in details: ' + data);
        setIsApiError(true);
      }
    });
  },[itemId]);

  return (
    <div className="details page">
      <section role="banner" className="search__banner">
        <Container fluid="lg">
          <Row>
            <h1>{SharedText.DetailsTitle}</h1>
          </Row>
        </Container>
      </section>

      <section>
        <Container fluid="lg">
          {isApiError && (
            <Row>
              <Col>
                <AlertModal
                  type={AlertTypes.DANGER}
                  title={AlertText.NoDetailedResultsTitle}
                  msg={AlertText.NoDetailedResultsMsg}
                />
              </Col>
            </Row>
          )}
            <ResultExtended
              key={itemId}
              datakey={itemId + '-' + 1}
              setOpenItems={[itemId]}
              openItems={itemId}
              data={resultsData}
            />
        </Container>
      </section>
    </div>
  );
};

export default Details;
