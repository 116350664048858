export enum AlertTypes {
	SUCCESS = 'success',
	WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info'
}

export enum AlertText {
  NoResultsTitle = 'Brak wyników',
  NoResultsMsg = 'Nie mamy wyników tego wyszukiwania. Zmien parametry i ponownie kliknij "Szukaj".',
  NoDataTitle = 'Brak wyników',
  NoDataMsg = 'Coś poszło nie tak. Proszę odświez stronę i spróbuj ponownie.',
  NoDetailedResultsTitle = 'Brak wyników',
  NoDetailedResultsMsg = 'Wygląda na to ze usługa którą chcesz zobaczyć nie istnieje juz w naszym systemie lub wystąpił jakiś błąd. Proszę odświez stronę lub przejdz na stronę główną.'
}