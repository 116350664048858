export const filtersQuery = `
{
  "districts": *[_type == "district" && name != null] 
    { 
      "value": name,
      "label": name,
      "communies": *[_type == "commune" && references(^._id)] 
        {
          "value": name,
          "label": name
        }
        | order(label asc),
    }
    | order(label asc),
  "ranges": *[_type == "serviceRange" && name != null && name != "TO REMOVE"]
    {
      "value": name,
      "label": name,
    }
    | order(label asc),
  "targets": *[_type == "target" && name != null && name != "TO REMOVE"]
    {
      "value": name,
      "label": name,
    }
    | order(label asc),
}
`;

export const searchQuery = `
{
  "results": (*[_type == "service"
  && (count($phrases) == 0 || computedSearch match $phrases)
  && (($district == "" && count($communies) == 0) 
    || count((communies[]._ref)[@ in $communies]) > 0 
    || $district in districts[]._ref
    || count($communies) == 0 && $district in communies[] -> district._ref)
  && (count($ranges) == 0 || count((serviceRanges[]._ref)[@ in $ranges]) > 0)
  && (count($targets) == 0 || count((targets[]._ref)[@ in $targets]) > 0)
  && ($local == false || isLocalService == $local)
  && ($remote == false || isRemoteService == $remote)
  && ($commuting == false || isCommutingService == $commuting)
  && ($free == false || isFree == $free)
  && ($paid == false || isPaid == $paid)]
    { 
      _id,
      title,
      description,
      additionalConditions,
      "paymantInfo": {
        isFree,
        isPaid,
      },
      "contact": {
        phone,
        email,
        website
      },
      "types": serviceTypes[]->name,
      openingInfo,
      providerAddress,
      "ranges": serviceRanges[]->name,
      "targets": targets[]->name,
      "localService": {
        "available": isLocalService,
        "address": select(
          isAddressTheSameAsProvider => 
            providerAddress, 
            address)
      },
      "remoteService": {
        "available": isRemoteService,
        "website": remoteServiceWebsite
      },
      "serviceWithCommuting": isCommutingService,
      _score,
      computedSearch
    }
    | score(computedSearch match $phrases)
    | order(_score desc)[$skip...$take]),
  "count": count(*[_type == "service"
  && (count($phrases) == 0 || computedSearch match $phrases)
  && (($district == "" && count($communies) == 0) 
    || count((communies[]._ref)[@ in $communies]) > 0 
    || $district in districts[]._ref 
    || count($communies) == 0 && $district in communies[] -> district._ref)
  && (count($ranges) == 0 || count((serviceRanges[]._ref)[@ in $ranges]) > 0)
  && (count($targets) == 0 || count((targets[]._ref)[@ in $targets]) > 0)
  && ($local == false || isLocalService == $local)
  && ($remote == false || isRemoteService == $remote)
  && ($commuting == false || isCommutingService == $commuting)
  && ($free == false || isFree == $free)
  && ($paid == false || isPaid == $paid)])
}
`

export const singleServiceQuery = `
{
  "result": *[_type == "service" && _id == $id][0]
    { 
      _id,
      title,
      description,
      additionalConditions,
      "paymantInfo": {
        isFree,
        isPaid,
      },
      "contact": {
        phone,
        email,
        website
      },
      "types": serviceTypes[]->name,
      openingInfo,
      providerAddress,
      "ranges": serviceRanges[]->name,
      "targets": targets[]->name,
      "localService": {
        "available": isLocalService,
        "address": select(
          isAddressTheSameAsProvider => 
            providerAddress, 
            address)
      },
      "remoteService": {
        "available": isRemoteService,
        "website": remoteServiceWebsite
      },
      "serviceWithCommuting": isCommutingService,
      _score,
      computedSearch
   }
}
`