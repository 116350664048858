// Importing styles
import Brand from "../brand/Brand";
import "./article.scss";

const Article = (props) => {

  return (
    <article className={`article ${props.selector}`}>
      <header>
        <h2>
          <div dangerouslySetInnerHTML={{ __html: props.title }} />
        </h2>
      </header>
      <section>
          <p dangerouslySetInnerHTML={{ __html: props.content }} />
      </section>
      <footer></footer>
    </article>
  );
};

export default Article;
