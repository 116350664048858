// Importing queries and client
import { sanityClient } from "../lib/sanity";
import { filtersQuery, searchQuery, singleServiceQuery } from "../lib/queries";
import removeDiacritics from "../utils/stringUtils";

export async function getDatafromSanity() {
  try {
    const response = await sanityClient.fetch(filtersQuery);
    return await response;
  } catch (error) {
    console.log("get data from CMS error: " + error);
    return { type: 'error', body: error };
  }
}

export async function getResultsfromSanity(searchParams, page, pageSize) {
  try {
    const params = {
      ...searchParams,
      skip: (page - 1) * pageSize,
      take: page * pageSize,
    };

    params.phrases = params.phrases
      .split(" ")
      .map((phrase) => `${removeDiacritics(phrase)}*`.toLowerCase().trim())
      .filter((phrase) => phrase.length > 0);

    const response = await sanityClient.fetch(searchQuery, params);
    return await response;

  } catch (error) {
    console.log("get results from CMS error: " + error);
    return { type: 'error', body: error };
  }
}

export async function getSingleResultfromSanity(id) {
  try {
    const params = {      
      id: id
    };

    const response = await sanityClient.fetch(singleServiceQuery, params);
    return await response;

  } catch (error) {
    console.log("get single result from CMS error: " + error);
    return { type: 'error', body: error };
  }
}
