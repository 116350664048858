// Importing styles
import './alert.scss';

//Importing modules
import Alert from 'react-bootstrap/Alert';

const AlertModal = ({type, title, msg}) => {

  return (
    <Alert variant={type}>
      <Alert.Heading>{title}</Alert.Heading>
      <hr />
      <p className="mb-0">{msg}</p>
    </Alert>
  );
};

export default AlertModal;
