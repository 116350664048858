// Importing styles
import "./search.scss";

// Importing modules
import React, { useState } from "react";
import SearchWidget from "../../components/searchWidget/SearchWidget";
import { Container, Row, Col } from "react-bootstrap";
import Results from "../../components/results/Results";
import ContentSpinner from '../../components/loader/contentSpinner';
import AlertModal from '../../components/alerts/Alert';
import { AlertTypes, AlertText } from '../../data/alerts.ts';
import { SharedText } from '../../data/main.ts';

const Search = () => {
  const [params, setParams] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isApiError, setIsApiError] = useState(false);

  const handleWidgetData = function (data) {
    setParams(data);
  };

  return (
    <div className="search page">
      <section role="banner" className="search__banner">
        <Container fluid="lg">
          <Row>
            <h1>{SharedText.SearchTitle}</h1>
          </Row>
        </Container>
      </section>
      <section>
        <SearchWidget
          layout={'large'}
          isPaginationVisible={true}
          onParamsChange={handleWidgetData}
          setCurrentPage={setCurrentPage}
          setLoading={setLoading}
          setIsApiError={setIsApiError}
        />
      </section>
      <section>
        <Container fluid="lg">
          {isApiError && (
            <Row>
              <Col>
                <AlertModal
                  type={AlertTypes.DANGER}
                  title={AlertText.NoDataTitle}
                  msg={AlertText.NoDataMsg}
                />
              </Col>
            </Row>
          )}
              <ContentSpinner loading={loading} />
        </Container>
        { params && (
          <Results
            searchParams={params}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setLoading={setLoading}
            loading={loading}
            setIsApiError={setIsApiError}
          />
        )}
      </section>
    </div>
  );
};

export default Search;
