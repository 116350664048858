// Importing styles

// Importing modules
import {
  Globe2,
  Telephone,
} from 'react-bootstrap-icons';
import { ContactTitles } from '../../data/main.ts';

const Brand = () => {
  return (
    <div role="contentinfo" className="result__contact">
      <h3 className="section-title">
        FEDERACJA ORGANIZACJI POZARZĄDOWYCH MIASTA BIAŁYSTOK
      </h3>
      <div className="section-table">
        <div className="table-row">
          <div className="table-col__wrapper">
            <div className="table-col icon">
              <Telephone />
            </div>
            <div className="table-col name" hidden>{ContactTitles.PhoneTitle}:</div>
          </div>
          <div className="table-col__wrapper">
            <div className="table-col value">
              <a href="tel:69239418">69239418</a>
            </div>
          </div>
        </div>
        <div className="table-row">
          <div className="table-col__wrapper">
            <div className="table-col icon">
              <Globe2 />
            </div>
            <div className="table-col name" hidden>{ContactTitles.WebsiteTitle}:</div>
          </div>
          <div className="table-col__wrapper">
            <div className="table-col value">
              <a
                href="https://www.federacja.bialystok.pl"
                target="_blank"
                rel="noreferrer"
              >
                www.federacja.bialystok.pl <span class="screen-readers-only">otwiera nowe okno</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brand;
