// Importing styles
import "./loader.scss";

import React, { useState, useLayoutEffect } from 'react';

const PageSpinner = () => {

  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };
    
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
      return () => document.removeEventListener('load', handleLoad);
    }
  }, []);

  return (
    <div className={`spinner-container ${isLoading ? 'visible' : 'hidden'}`}>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default PageSpinner;
