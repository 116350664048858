// Importing styles

// Importing modules
import ResultContact from './ResultContact.js';
import PropTypes from 'prop-types';

const ResultDescription = (props) => {
  const descriptions = props?.descriptions;
  const single = props?.single;
  const itemId = props?.itemId;
  const contact = props?.contact;

  return (
    <section className="result__section">
      {descriptions && descriptions.length > 0 && (
        <div className="section-description">
          {descriptions.length === 1 && <p> {descriptions[0]}</p>}
          {descriptions.length > 1 && (
            <ul>
              {descriptions.map((description, index) => (
                <li key={index}>{description}</li>
              ))}
            </ul>
          )}
        </div>
      )}
      <ResultContact contact={contact} itemId={itemId} single={single} />
    </section>
  );
};
ResultDescription.propTypes = {
  descriptions: PropTypes.array,
  tags: PropTypes.object
};
export default ResultDescription;
