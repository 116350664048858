// Importing styles
import "./home.scss";

// Importing modules
import React , { useState }from "react";
import Banner from "../../components/banner/Banner";
import SearchWidget from "../../components/searchWidget/SearchWidget";
import Article from "../../components/article/Article";
import { Container, Row, Col } from "react-bootstrap";
import AlertModal from "../../components/alerts/Alert";
import { AlertTypes, AlertText } from '../../data/alerts.ts';
import { ArticleText } from "../../data/main.ts";

const Home = () => {
  const [isApiError, setIsApiError] = useState(false);

  return (
    <div className="home page">
      <Banner />
      <section>
        <SearchWidget
          layout={'small'}
          isPaginationVisible={false}
          setIsApiError={setIsApiError}
        />
      </section>
      <section>
        <Container fluid="lg">
          {isApiError && (
            <Row>
              <Col>
                <AlertModal
                  type={AlertTypes.DANGER}
                  title={AlertText.NoDataTitle}
                  msg={AlertText.NoDataMsg}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={4} xs={12}>
              <Article
                title={ArticleText.article1Title}
                content={ArticleText.article1Text}
              />
            </Col>
            <Col md={4} xs={12}>
              <Article
                title={ArticleText.article2Title}
                content={ArticleText.article2Text}
              />
            </Col>
            <Col md={4} xs={12}>
              <Article
                title={ArticleText.article3Title}
                content={ArticleText.article3Text}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Home;
