// Importing styles
import '../../components/loader/loader.scss'

// Importing modules
import Result from "../../components/results/Result";
import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";

// Importing queries and client
import { getResultsfromSanity } from "../../services/SearchService";
import { getResultsfromApi } from "../../clients/SearchApiClient"
import PaginationComponent from "../pagination/Pagination";
import AlertModal from "../../components/alerts/Alert";
import { AlertTypes, AlertText } from "../../data/alerts.ts";

const Results = ({
  searchParams,
  currentPage,
  setCurrentPage,
  setLoading,
  loading,
  setIsApiError,
}) => {
  const [resultsData, setResultsData] = useState([]);
  const [count, setCount] = useState(-1);
  const [recordsPerPage] = useState(5);
  const [openItems, setOpenItems] = useState([]);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getResultsfromApi(searchParams, currentPage, recordsPerPage).then(
      (data) => {
        console.log(data);
        // setResultsData(data.results);
          setResultsData(data.entries);
          setCount(data.total);
          setLoading(false);
          setIsError(false);
          setIsApiError(false);
      }

    );
    // getResultsfromSanity(searchParams, currentPage, recordsPerPage).then(
    //   (data) => {
    //     console.log("old", data);
    //     if (data && !("type" in data)) {
    //       setResultsData(data.results);
    //       setCount(data.count);
    //       setLoading(false);
    //       setIsError(false);
    //       setIsApiError(false);
    //     } else {
    //       console.log("There are errors in results: " + data);
    //       setLoading(false);
    //       setIsError(true);
    //       setIsApiError(true);
    //     }
    //   }
    // );
    window.scrollTo(0, 0);
  }, [searchParams, currentPage, recordsPerPage, setLoading, setIsApiError]);

  const renderCustomPagination = () => {
    let nPages = Math.ceil(count / recordsPerPage);
    return (
      !isError &&
      nPages > 1 && (
        <PaginationComponent
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setOpenItems={setOpenItems}
        />
      )
    );
  };

  const renderResults = (results) => {
    return (
      !isError && (
        <div>
          {results.map((item, index) => (
            <Result
              key={index}
              datakey={index + "-" + currentPage}
              setOpenItems={setOpenItems}
              openItems={openItems}
              data={item}
            />
          ))}
        </div>
      )
    );
  };

  return (
    <Container
      fluid="lg"
      className={`results-wrapper ${loading ? "hidden" : "visible"}`}
    >
      <Row>
        {count === 0 && (
          <AlertModal
            type={AlertTypes.INFO}
            title={AlertText.NoResultsTitle}
            msg={AlertText.NoResultsMsg}
          />
        )}
        <Col>{resultsData && renderResults(resultsData)}</Col>
      </Row>
      <Row>
        <Col>{renderCustomPagination()}</Col>
      </Row>
    </Container>
  );
};

export default Results;
