/* eslint-disable jsx-a11y/anchor-is-valid */
// Importing styles
import './pagination.scss';


//Importing modules
import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ nPages, currentPage, setCurrentPage, setOpenItems }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const first = 1;
  const last = pageNumbers.length;

  let start = 2,
    end = pageNumbers.length - 1;
    
  if (currentPage - 1 > 1) {
    start = currentPage - 1;
  }
  if (currentPage + 1 < pageNumbers.length - 1) {
    end = currentPage + 1;
  }

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <Pagination>
      <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
      <Pagination.Item
        key={first}
        onClick={() => {
          setCurrentPage(first);
          setOpenItems([]);
        }}
        active={currentPage === first}
      >
        {first}
      </Pagination.Item>
      {start !== 2 && <Pagination.Ellipsis />}
      {pageNumbers.slice(start - 1, end).map((number) => (
        <Pagination.Item
          key={number}
          onClick={() => {
            setCurrentPage(number);
            setOpenItems([]);
          }}
          active={currentPage === number}
        >
          {number}
        </Pagination.Item>
      ))}
      {end !== (pageNumbers.length - 1) && <Pagination.Ellipsis />}
      <Pagination.Item
        key={last}
        onClick={() => {
          setCurrentPage(last);
          setOpenItems([]);
        }}
        active={currentPage === last}
      > 
        {last}
      </Pagination.Item>
      <Pagination.Next
        onClick={nextPage}
        disabled={currentPage === pageNumbers.length}
      />
    </Pagination>
  );
};

export default PaginationComponent;
