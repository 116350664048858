// Importing styles
import './about.scss';

// Importing modules
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { SharedText } from '../../data/main.ts';

const About = () => {
  
  return (
    <div className="about page">
      <section role="banner" className="search__banner">
        <Container fluid="lg">
          <Row>
            <h1>{SharedText.AboutTitle}</h1>
          </Row>
        </Container>
      </section>

      <section>
        <Container fluid="lg">
          <div className="about-wrapper">
            <article className="article">
              <header className="about__header">
                <h2 className="about-title">{SharedText.AboutTitle}</h2>
              </header>
              <section className="about__section">
                <p>
                  Projekt realizowany jest przez{' '}
                  <a href="www.bialystokfederacja.pl">
                    Federację Organizacji Pozarządowych Miasta Białystok
                  </a>
                  , finansowany w ramach programu Fundusz Inicjatyw
                  Obywatelskich na lata 2021-2030 NOWEFIO – edycja 2022
                </p>
                <p>
                  Projekt realizowany jest w partnerstwie z samorządem
                  województwa reprezentowanym przez{' '}
                  <a href="http://www.rops-bialystok.pl/">
                    Regionalny Ośrodek Polityki Społecznej
                  </a>
                  .
                </p>
                <p>
                  Głównym celem projektu jest wypracowanie i wdrożenie
                  zarządzania (narzędzi, metodyki i menagerów) skoordynowanych
                  usług społecznych przez Federację we współpracy z instytucjami
                  publicznymi i prywatnymi w mieście Białystok oraz 14 powiatach
                  woj. podlaskiego. Dzięki temu programowanie, planowanie i
                  dostarczanie usług będzie się odbywać w odpowiedzi na
                  adekwatne potrzeby lokalnych społecznościach: miasta Białystok
                  i powiatowo-gminnych.
                </p>
                <p>Projekt ma na celu:</p>
                <ul>
                  <li>
                    <b>
                      opracowanie i wdrożenie narzędzia
                    </b>{' '}
                    „KOSZYK USŁUG”, w którym znajdą się zebrane i skatalogowane
                    usługi społeczne realizowane przez m.in.: instytucje
                    publiczne i organizacje społeczne z terenu województwa
                    podlaskiego (powstanie 15 baz danych – dla miasta
                    Białegostoku, 14 powiatowo-gminnych)
                  </li>
                  <li>
                    <b>
                      dwudniowe szkolenia realizowane w 14 powiatach
                    </b>{' '}
                    – w zakresie budowania bazy danych usług realizowanych w
                    powiatach oraz zagadnienia Centra Usług Społecznych
                  </li>
                  <li>
                    <b>
                      zarządzanie usługami lokalnie
                    </b>{' '}
                    (prowadzenie i aktualizowanie KOSZYKA USŁUG, spotkania
                    aktualizacyjne bazy danych, która powstanie do Koszyka Usług
                    - FORA USŁUG, programowanie usług, standaryzacja e-usług
                    społecznych)
                  </li>
                  <li>
                    <b>
                      zbudowanie/podpisanie 14 porozumień powiatowych
                    </b>{' '}
                    pomiędzy Federacją Organizacji Pozarządowych Miasta
                    Białegostoku a organizacjami społecznymi z poszczególnych
                    powiatów
                  </li>
                  <li>
                    <b>
                      doradztwo i wsparcie
                    </b>{' '}
                    dla organizacji społecznych i menagerów powiatowych w
                    zakresie
                  </li>
                  <li>
                    <b>
                      organizację Krajowego Forum Usług Społecznych
                    </b>
                    upowszechniającego efekty i trudności współzarządzania
                  </li>
                </ul>
              </section>
              <footer className="about__footer">
                <p>Biuro projektu:</p>
                <p>Federacja Organizacji Pozarządowych Miasta Białystok</p>
                <p> ul. KsiędzaAdama Abramowicza 1, 15-872 Białystok</p>
                <p>
                  e-mail:{' '}
                  <a href="mailto:bialystokfederacja@gmail.com">
                    bialystokfederacja@gmail.com
                  </a>
                </p>
              </footer>
            </article>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default About;
