// Importing styles
import "./App.scss";

// Importing modules
import React from "react";
import TagManager from "react-gtm-module";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import PageSpinner from "./components/loader/PageSpinner";
import Details from "./pages/details/Details";
import Home from "./pages/home/Home";
import Search from "./pages/search/Search";
import About from "./pages/about/About";
import Error404 from "./pages/error404/Error404";

function App() {
  const Layout = (props) => {
    // const tagManagerArgs = {
    //   gtmId: "GTM-5RS2W9W",
    // };

    // TagManager.initialize(tagManagerArgs);

    return (
      <div className="App">
        <PageSpinner />
        <Header
          sections={router.routes[0].children}
          location={router.state.location.pathname}
        />
        <main className="content">
          <Outlet />
        </main>
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Home />,
          title: 'STRONA GŁÓWNA',
          hidden: false,
        },
        {
          path: '/About',
          element: <About />,
          title: 'O USŁUGACH',
          hidden: false,
        },
        {
          path: '/Search',
          element: <Search />,
          title: 'Search',
          hidden: true,
        },
        {
          path: '/Details/:id',
          element: <Details />,
          title: 'Details',
          hidden: true,
        },
        {
          path: '*',
          element: <Error404 />,
          title: '404',
          hidden: true,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
