// Importing styles
import "../article/article.scss";
import "./results.scss";
import "../../assets/styles/_buttons.scss";

//Importing modules

import ResultDetails from "./ResultDetails";
import ResultDescription from "./ResultDescription";
import ContentSpinner from "../../components/loader/contentSpinner";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import { TagsTypes } from "../../data/main.ts";
import { useState } from "react";

const ResultExtended = (props) => {
  const itemId = props?.data?._id;
  const name = props?.data?.title;
  const descriptions = props?.data?.description;
  const details = {
    ranges: props?.data?.ranges,
    conditions: props?.data?.additionalConditions,
    groups: props?.data?.targets,
    deliveryAddress: props?.data?.providerAddress,
    realizationAddress: props?.data?.localService?.address,
    remoteAddres: props?.data?.remoteService?.website,
    localAddress: props?.data?.localService?.address,
    openingInfo: props?.data?.openingInfo,
  };
  const contact = props?.data?.contact;
  const tags = {
    isPaid: props?.data?.paymantInfo?.isPaid,
    isFree: props?.data?.paymantInfo?.isFree,
    isRemote: props?.data?.remoteService?.available,
    isLocal: props?.data?.localService?.available,
    isCommuting: props?.data?.serviceWithCommuting,
  };

  return (
    <div className="result-wrapper">
      <article className="article result">
        {!props?.data ? (
          <ContentSpinner loading={true} />
        ) : (
          <>
            <header className="result__header">
              {tags && Object.values(tags).some((v) => v) && (
                <div className="section-tags">
                  {tags.isPaid && <span className="tag">{TagsTypes.PAID}</span>}
                  {tags.isFree && <span className="tag">{TagsTypes.FREE}</span>}
                  {tags.isLocal && (
                    <span className="tag">{TagsTypes.LOCAL}</span>
                  )}
                  {tags.isRemote && (
                    <span className="tag">{TagsTypes.REMOTE}</span>
                  )}
                  {tags.isCommuting && (
                    <span className="tag">{TagsTypes.COMMUTING}</span>
                  )}
                </div>
              )}
              <h2 className="result-title">{name}</h2>
            </header>
            <ResultDescription
              descriptions={descriptions}
              tags={tags}
              contact={contact}
              itemId={itemId}
              single={true}
            />
            <footer className="result__footer">
              <ResultDetails details={details} />
            </footer>
          </>
        )}
      </article>
    </div>
  );
};

ResultExtended.propTypes = {
  name: PropTypes.object,
  descriptions: PropTypes.array,
  details: PropTypes.object,
  contact: PropTypes.object,
  tags: PropTypes.object,
};

export default ResultExtended;
